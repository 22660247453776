import { graphql, Link } from "gatsby";
import _ from "lodash";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

type CategoryType = {
  name: string;
  totalCount: number;
  nodes: { frontmatter: { date: string } }[];
};

export default function CategoriesPage(props: {
  data: {
    allMarkdownRemark: {
      group: CategoryType[];
    };
  };
}) {
  return (
    <Layout>
      <SEO
        post={{
          title: "Categories",
          path: "/categories",
        }}
      />
      <div className="col col-12 col-xl-8 mx-auto p-3 rounded bg-white">
        <h1 className="h3">Categories</h1>
        {props.data.allMarkdownRemark.group.map((g) => (
          <Tag category={g} key={g.name} />
        ))}
      </div>
    </Layout>
  );
}

function Tag({ category }: { category: CategoryType }) {
  return (
    <div className="mb-3">
      <Link
        to={"/categories/" + _.kebabCase(category.name)}
        className="text-reset text-decoration-none"
      >
        <div>
          <b>{category.name}</b> - {category.totalCount}{" "}
          {category.totalCount > 1 ? "posts" : "post"}
        </div>
        <div className="text-muted">
          Latest post {category.nodes[0].frontmatter.date}
        </div>
      </Link>
    </div>
  );
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      group(field: frontmatter___categories, limit: 1) {
        name: fieldValue
        totalCount
        nodes {
          frontmatter {
            date(formatString: "YYYY-MM-DD")
          }
        }
      }
    }
  }
`;
